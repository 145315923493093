<!-- <div>
  <app-countdown-bar></app-countdown-bar>
</div> -->
<div>
  <div *ngIf="activeAnnouncementBanner == false && hideMasterCardArea == false"
  style="display: grid;align-items: center;text-align: center;"
  [ngStyle]="{'margin-top': announcementBannerHeight + 'px', 'background-color': blackFridayPageIsActive ? '#000' : '#0b051e'}" class="holidayseason-shoutbar">
  <div *ngIf="hostUnseenMessageBand === false ">
    <img height="49px" fetchpriority="high" loading="eager"
      src="{{klarnaShoutbarImage}}" alt="Mastercard Banner" priority style="max-width: 100%" *ngIf="!blackFridayPageIsActive">
      
      <img height="55px" fetchpriority="high" loading="eager" (click)="copyToBlackFridayDiscountCode()"
      src="{{commercialShoutBarSource}}" alt="Black Friday Shoutbar Banner"  priority style="max-width: 100%;cursor: pointer;" *ngIf="blackFridayPageIsActive">
  </div>

  <!-- <a class="inside-shippn-shoutbar" (click)="goToCommercialBannerPage()"
    *ngIf="hostUnseenMessageBand === false && !isHomePage" style="background-color: #9e0000;">
    <img fetchpriority="high" loading="eager" class="shoutbar-image-23" height="55px" src="{{commercialShoutBarSource}}"
      alt="Black Friday 2024 Shoutbar" priority style="object-fit: cover;">

  </a> -->

  <div *ngIf="hostUnseenMessageBand === true" class="host-unseenmessages-band">
    <p>
      Hey there! You have new messages. Click to respond!
    </p>
    <button class="btn" data-toggle="modal" data-target="#notificationModal" (click)="openNotificationcenterArea()">
      Go to messages
    </button>

  </div>

</div>
<header style="min-height: 61.15px;background-color: white;border-bottom: 1px solid #e6e6e6;"
  [ngStyle]="{'margin-top': announcementBannerHeight + 'px'}" class="for-desktop">
  <div class="container shippn-homepage-container shippn-header-menu-desktop-container">
    <nav style="background-color: white;border-color: lightgray;padding-left: 0;padding-right: 0;"
      class="navbar navbar-expand-lg navbar-light shippn-navbar shippn-navbarv2">
      <!-- Logo-->
      <span class="navbar-brand" [routerLink]="['/', lang,'home']" data-toggle="collapse"
        data-target=".navbar-collapse.show">
        <img ngSrc="shippn-logo-black.webp" width="170" height="40" style="position: relative;" fetchpriority="high"
          title="Shippn" class="img-logo navbar-shippn-logo" alt="Shippn Black Logo" priority>
      </span>
      <!-- Mobile Nav Area-->
      <div class="collapse navbar-collapse mobile-nav-padding" id="navbarSupportedContent" *ngIf="!isShopNowActive">
        <ul class="navbar-nav ml-auto">
          <li *ngIf="isLogin" class="hide-for-mobile dropdownRow d-block d-xs-block d-sm-block d-md-block d-lg-none">
            <span style="display: block;color: #000; cursor: pointer;" translate="no"><strong
                translate="no">{{loggedUser?.name}}
                {{loggedUser?.surname}}</strong></span>
          </li>
          <!-- Mobile User Area -->
          <!-- Order & Dashboard -->
          <span style="cursor: pointer; color: #000; " *ngIf="loggedUser?.type == 1" class="nav-link hide-for-desktop"
            [routerLink]="['/',lang,'member','packs-expected']" routerLinkActive="active" data-toggle="collapse"
            data-target=".navbar-collapse.show">Orders <i class="fas fa-box-open"></i></span>
          <span style="cursor: pointer; color: #000; " *ngIf="loggedUser?.type == 2 && !isAffiliate"
            class="nav-link hide-for-desktop" [routerLink]="['/',lang, 'member','host-packs-expected']"
            routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">Dashboard <i
              class="fas fa-box-open"></i></span>

          <span style="cursor: pointer; color: #000; " *ngIf="loggedUser?.type == 2 && isAffiliate"
            class="nav-link hide-for-desktop" [routerLink]="['/',lang, 'member','my-store']" routerLinkActive="active"
            data-toggle="collapse" data-target=".navbar-collapse.show">My Store <i class="fas fa-box-open"></i></span>

          <span style="cursor: pointer; color: #000; " *ngIf="loggedUser?.type == 4" class="nav-link hide-for-desktop"
            [routerLink]="['/',lang, 'member','affiliate-dashboard']" routerLinkActive="active" data-toggle="collapse"
            data-target=".navbar-collapse.show">My Store <i class="fas fa-box-open"></i></span>

          <span style="cursor: pointer; color: #000; " *ngIf="loggedUser?.type == 5" class="nav-link hide-for-desktop"
            [routerLink]="['/',lang, 'b2b','create-shipment']" routerLinkActive="active" data-toggle="collapse"
            data-target=".navbar-collapse.show"> B2B Dashboard <i class="fas fa-box-open"></i></span>
          <!-- Order & Dashboard -->
          <!-- Messages -->
          <span class="nav-link hide-for-desktop" (click)="openNotificationcenterArea()" data-toggle="collapse"
            data-target=".navbar-collapse.show">
            <span style="gap:10px; color:#000; position: relative;" class="message-link-wrapper" data-toggle="modal"
              data-target="#notificationModal">
              Messages
              <span class="notification-center-messages-count" style="position: relative;"
                [ngClass]="notifyMessagesCount > 0 ? 'shippn-orange' : 'shippn-black'">
                {{notifyMessagesCount ? notifyMessagesCount: 0}}
              </span>
            </span>

          </span>
          <!-- Messages -->
          <li class="nav-item dropdown hide-for-desktop" *ngIf="isLogin" translate="no">
            <span style="cursor: pointer; color: #000;" class="nav-link dropdown-toggle" href="#"
              id="navbarUserDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <strong translate="no">{{loggedUser?.name}}
                {{loggedUser?.surname}}</strong>
            </span>
            <div class="dropdown-menu" aria-labelledby="navbarUserDropdown" data-toggle="collapse"
              data-target=".navbar-collapse.show">
              <span style="cursor: pointer;" class="dropdown-item" [routerLink]="['/', lang,'settings','profile']"
                data-toggle="collapse" data-target=".navbar-collapse.show">
                My Profile
                <i style="float: right;" class="fas fa-user"></i>
              </span>
              <span style="cursor: pointer;" class="dropdown-item"
                [routerLink]="['/', lang,'settings','change-password']" data-toggle="collapse"
                data-target=".navbar-collapse.show">
                Change Password
                <i style="float: right;" class="fas fa-lock"></i>
              </span>



            </div>
          </li>
          <li class="nav-item" *ngIf="!hello10Variant">
            <span style="cursor: pointer;color:#000" class="nav-link" [routerLink]="['/',lang,'stores']"
              routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
              Top Global Stores
            </span>
          </li>
          <li class="nav-item dropdown" *ngIf="!hello10Variant">
            <span style="cursor: pointer; color: #000;" class="nav-link dropdown-toggle" href="#"
              id="navbarLocationsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              Locations
            </span>
            <div class="dropdown-menu" aria-labelledby="navbarLocationsDropdown">
              <span *ngFor="let locationCountry of hostCountries; let i=index;" style="cursor: pointer;"
                class="dropdown-item" [routerLink]="['/', lang,'shop-from', locationCountry?.code]" [queryParams]="loggedUser?.has_orders === false ? { user: 'new' } : null"
                routerLinkActive="active" data-toggle="collapse"
                data-target=".navbar-collapse.show">{{locationCountry?.name}}</span>

              <span style="cursor: pointer;" class="dropdown-item" [routerLink]="['/', lang,'shop-from']"
                data-toggle="collapse" data-target=".navbar-collapse.show">Show All</span>

            </div>
          </li>
          <!-- Locations -->
          <!-- Help-->
          <li class="nav-item dropdown" *ngIf="!hello10Variant">
            <span style="cursor: pointer; color: #000;" class="nav-link dropdown-toggle" href="#"
              id="navbarHelpDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

              Help
            </span>
            <div class="dropdown-menu" aria-labelledby="navbarHelpDropdown">
              <span style="cursor: pointer;" class="dropdown-item" [routerLink]="['/',lang,'about']"
                routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">About Us</span>
              <span style="cursor: pointer;" class="dropdown-item" [routerLink]="['/',lang,'howitworks-for-shoppers']"
                routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">For Shoppers</span>
              <span style="cursor: pointer;" class="dropdown-item" [routerLink]="['/',lang,'howitworks-for-hosts']"
                routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">For Hosts</span>
              <span style="cursor: pointer;" class="dropdown-item" [routerLink]="['/',lang,'faq']"
                routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">FAQs</span>
              <span style="cursor: pointer;" class="dropdown-item" [routerLink]="['/',lang, 'trust-safety']"
                routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">Trust &
                Safety</span>
              <span style="cursor: pointer;" class="dropdown-item" [routerLink]="['/',lang, 'prohibited-items']"
                routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">Prohibited
                Items</span>
              <span style="cursor: pointer;" class="dropdown-item" [routerLink]="['/', lang, 'shipping-standards']"
                routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">Shipping
                Standards</span>
              <span style="cursor: pointer;" class="dropdown-item" [routerLink]="['/', lang, 'responsible-shipping']"
                routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">Responsible
                Shipping</span>
              <span style="cursor: pointer;" class="dropdown-item" [routerLink]="['/', lang, 'contact-us']"
                routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">Contact Us</span>
            </div>
          </li>
          <!-- Help-->
          <li class="nav-item" *ngIf="!hello10Variant">

            <span style="cursor: pointer;color:#000" class="nav-link" [routerLink]="['/',lang,'bfm','explanation']"
              routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
              Buy For Me Service
            </span>
          </li>
          <li *ngIf="(!loggedUser || (loggedUser?.type == 1) ) && !hello10Variant" class="nav-item">

            <span style="cursor: pointer; color: #000;" class="nav-link"
              [routerLink]="['/',lang, 'shipping-cost-calculator']" routerLinkActive="active" data-toggle="collapse"
              data-target=".navbar-collapse.show">Shipping Cost
              Calculator</span>
          </li>

          <li *ngIf="isLogin && !hello10Variant"
            class="hide-for-mobile nav-item d-block d-xs-block d-sm-block d-md-block d-lg-none" data-toggle="collapse"
            data-target=".navbar-collapse.show">
            <span style="display: flex; gap:10px; font-size: 14px; color:#000;" class="nav-link" data-toggle="modal"
              data-target="#notificationModal" (click)="openNotificationcenterArea()">
              <img src="assets/img/header/message.svg" alt="Shippn Messages Icon" loading="lazy">
              Messages
              <span class="notification-center-messages-count" style="position: relative;"
                [ngClass]="notifyMessagesCount > 0 ? 'shippn-orange' : 'shippn-black'">
                {{notifyMessagesCount ? notifyMessagesCount: 0}}
              </span>
            </span>
          </li>

          <li *ngIf="isLogin && !hello10Variant"
            class="hide-for-mobile nav-item d-block d-xs-block d-sm-block d-md-block d-lg-none">
            <img src="assets/img/header/orders.svg" alt="Shippn Icon Orders" loading="lazy">
            <span style="cursor: pointer; color: #000;" *ngIf="loggedUser?.type == 1" class="nav-link"
              [routerLink]="['/',lang, 'member','packs-expected']" routerLinkActive="active" data-toggle="collapse"
              data-target=".navbar-collapse.show">Orders</span>
            <span style="cursor: pointer; color: #000;" *ngIf="loggedUser?.type == 2" class="nav-link"
              [routerLink]="['/',lang, 'member','host-packs-expected']" routerLinkActive="active" data-toggle="collapse"
              data-target=".navbar-collapse.show">Dashboard</span>
            <span style="cursor: pointer; color: #000;" *ngIf="loggedUser?.type == 4" class="nav-link"
              [routerLink]="['/',lang, 'member','affiliate-dashboard']" routerLinkActive="active" data-toggle="collapse"
              data-target=".navbar-collapse.show">My Store</span>
            <span style="cursor: pointer; color: #000;" *ngIf="loggedUser?.type == 5" class="nav-link"
              [routerLink]="['/',lang, 'b2b','create-shipment']" routerLinkActive="active" data-toggle="collapse"
              data-target=".navbar-collapse.show">B2B Dashboard</span>
          </li>

          <li *ngIf="isLogin && !hello10Variant"
            class="hide-for-mobile nav-item d-block d-xs-block d-sm-block d-md-block d-lg-none">
            <span style="cursor: pointer; color: #000;" class="nav-link" [routerLink]="['/',lang,'settings','profile']"
              routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">My Profile</span>
          </li>

          <li (click)="logout()" *ngIf="isLogin && !hello10Variant"
            class="hide-for-mobile nav-item d-block d-xs-block d-sm-block d-md-block d-lg-none">
            <span style="cursor: pointer; color: #000;" class="nav-link" data-toggle="collapse"
              data-target=".navbar-collapse.show">Logout <i class="fas fa-sign-out-alt"></i></span>
          </li>
          <li class="nav-item dropdown" *ngIf="!isLogin && !hello10Variant">
            <span class="nav-link dropdown-toggle" href="#" id="navbarPartnerDropdown" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style=" color:#000; cursor: pointer;">
              Become a Partner
            </span>
            <span
                style="margin-left: 15px;background-color: red;color: white;padding: 2px 6px;border-radius: 6px;font-size: 9px; position:absolute; top: -8px; right: 20px;"
                class="new-badge">New</span>
            <div class="dropdown-menu" aria-labelledby="navbarPartnerDropdown">
              <span class="dropdown-item" [routerLink]="['/',lang,'b2b']" routerLinkActive="active"
                data-toggle="collapse" data-target=".navbar-collapse.show" style="cursor:pointer;">Create B2B Account</span>
              <span class="dropdown-item" [routerLink]="['/',lang,'become-a-host']" routerLinkActive="active"
                data-toggle="collapse" data-target=".navbar-collapse.show" style="cursor:pointer;">Become a Host</span>
              <span class="dropdown-item" [routerLink]="['/',lang,'become-an-affiliate']" routerLinkActive="active"
                data-toggle="collapse" data-target=".navbar-collapse.show" style="cursor:pointer;">Become an
                Affiliate</span>
              <span class="dropdown-item" [routerLink]="['/',lang,'shipper-network']" routerLinkActive="active"
                data-toggle="collapse" data-target=".navbar-collapse.show" style="cursor: pointer;">Shipping
                Companies</span>
            </div>
          </li>

          <li class="nav-item" *ngIf="!hello10Variant">
            <span style="cursor: pointer; color: #000;" class="nav-link" data-toggle="collapse"
              data-target=".navbar-collapse.show">

              <a href="https://www.shippn.com/blog"
                style="color:#000; text-decoration: none;" target="_blank">Blog</a></span>
          </li>
          <li class="nav-item" *ngIf="(loggedUser?.type == 1 || !loggedUser) && !hello10Variant" style="margin:0;">
            <span [routerLink]="['/',lang,'bfm']" style="cursor: pointer; color: #000; font-size: 16px; padding-left:0;"
              class="nav-link" *ngIf="!isthereBfmBasket" data-toggle="collapse" data-target=".navbar-collapse.show">
              <img src="assets/img/header/basket.svg" alt="Shippn Icon Basket" loading="lazy">
            </span>
            <span [routerLink]="['/',lang,'bfm']"
              [queryParams]="{anonymous_id: bfmUrlAnonymousId, cart_id: bfmUrlCartId }"
              style="cursor: pointer; color: #000; font-size: 16px;padding-left:0;" class="nav-link active-shopping-cart"
              *ngIf="isthereBfmBasket" data-toggle="collapse" data-target=".navbar-collapse.show">
              <img src="assets/img/header/basket.svg" alt="Shippn Icon Basket" loading="lazy">
              <div class="basketItemsCount" *ngIf="shoppingCartItems.length" translate="no">
                {{shoppingCartItems.length}}
              </div>
            </span>
          </li>
          <li *ngIf="!isLogin && !hello10Variant" class="nav-item">
            <span style="cursor: pointer;" class="nav-link btn-shippn-orange" [routerLink]="['/',lang,'signup']"
              routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">Sign Up</span>
          </li>

          <li *ngIf="!isLogin && !hello10Variant" class="nav-item " style="margin-left: 15px;">
            <span style="cursor: pointer;" class="nav-link btn-shippn-orange" [routerLink]="['/',lang,'signin']"
              routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">Sign In</span>
          </li>

          <li class="d-none d-xs-none d-sm-none d-md-none d-lg-block dropdown" *ngIf="isLogin && !hello10Variant">
            <span style="cursor: pointer;" class="dropdown-profile-toggle" data-target=".navbar-collapse.show"
              id="userDropdown" data-toggle="dropdown">
              <span style="position:relative">
                <img style="border-radius: 50%;" class="img-responsive img-circle profileimg" width="40" height="40"
                  title="Profile" src="{{loggedUser?.profile_image_url}}" alt="Shippn User Profile Picture"
                  onerror="this.style.display='none';" loading="lazy">
                <span class="notification-center-messages-count" *ngIf="notifyMessagesCount > 0"
                  style="position: absolute; top:-10px; right: -14px;"
                  [ngClass]="notifyMessagesCount > 0 ? 'shippn-orange' : 'shippn-black'">
                  {{notifyMessagesCount ? notifyMessagesCount: 0}}
                </span>
              </span>


            </span>
            <ul *ngIf="showDropdown" class="dropdown-profile dropdown-menu" aria-labelledby="userDropdown">
              <li class="dropdownRow" translate="no">
                <span style="display: block;padding: 10px;color: #000; font-size: 14px;" data-toggle="collapse"
                  data-target=".navbar-collapse.show"><strong translate="no">{{loggedUser?.name}}
                    {{loggedUser?.surname}}</strong></span>
              </li>
              <hr>
              <li *ngIf="isLogin" class="dropdownRow">
                <span style="display: flex; justify-content: space-between; padding: 10px;color: #000; font-size: 14px;"
                  class="nav-link" data-toggle="modal" data-target="#notificationModal"
                  (click)="openNotificationcenterArea()">
                  Messages <span class="notification-center-messages-count"
                    style="position: relative; top: 0; right: 0;"
                    [ngClass]="notifyMessagesCount > 0 ? 'shippn-orange' : 'shippn-black'">
                    {{notifyMessagesCount ? notifyMessagesCount: 0}}
                  </span>
                </span>
              </li>
              <li class="dropdownRow">
                <span (click)="goDashboard()" style="display: block;padding: 10px;color: #000; font-size: 14px;">
                  <span *ngIf="loggedUser?.type == 1" data-toggle="collapse"
                    data-target=".navbar-collapse.show">Orders</span>
                  <span *ngIf="loggedUser?.type == 2 && !isAffiliate" data-toggle="collapse"
                    data-target=".navbar-collapse.show">Dashboard</span>
                  <span *ngIf="loggedUser?.type == 2 && isAffiliate" data-toggle="collapse"
                    data-target=".navbar-collapse.show">My Store</span>
                  <span *ngIf="loggedUser?.type == 4" data-toggle="collapse" data-target=".navbar-collapse.show">My
                    Store</span>
                  <span *ngIf="loggedUser?.type == 5" data-toggle="collapse" data-target=".navbar-collapse.show">B2B
                    Dashboard</span>
                  <i style="float: right;" class="fas fa-box-open"></i>
                </span>
              </li>
              <li [routerLink]="['/', lang,'settings','profile']" class="dropdownRow">
                <span style="display: block;padding: 10px;color: #000;font-size:14px" data-toggle="collapse"
                  data-target=".navbar-collapse.show">
                  My Profile
                  <i style="float: right;" class="fas fa-user"></i>
                </span>
              </li>
              <li [routerLink]="['/',lang,'settings','change-password']" class="dropdownRow">
                <span style="display: block;padding: 10px;color: #000;font-size:14px" data-toggle="collapse"
                  data-target=".navbar-collapse.show">
                  Change Password
                  <i style="float: right;" class="fas fa-lock"></i>
                </span>
              </li>
              <hr>
              <li (click)="logout()" class="dropdownRow">
                <span style="display: block;padding: 10px;color: #000;font-size:14px" data-toggle="collapse"
                  data-target=".navbar-collapse.show">
                  Logout
                  <i style="float: right;" class="fas fa-sign-out-alt"></i>
                </span>
              </li>
            </ul>
          </li>
          <li class="nav-item nav-button">
            <span data-toggle="modal" data-target="#translateLanguage">
              <i class="fas fa-globe" style="margin-top: 5px; cursor: pointer; font-size: 25px; color: #101010;"></i>
            </span>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
<header class="shippn-main-header for-mobile">
  <section class="container shippn-homepage-container">
    <nav class="shippn-main-nav-mobile">
      <button class="navbar-brand" [routerLink]="['/', lang,'home']" data-toggle="collapse"
        data-target=".navbar-collapse.show" (click)="closeMobileMenu();">
        <img ngSrc="shippn-logo-black.webp" width="170" height="40" style="position: relative;" fetchpriority="high"
          title="Shippn" class="img-logo navbar-shippn-logo" alt="Shippn Black Logo" priority>
      </button>
      <div class="header-allways-on-menu">
        <button class="btn btn-shippn-header-user" (click)="goProfileOrSignIn();closeMobileMenu();"
          *ngIf="!notifyMessagesCount && !hello10Variant">
          <img src="assets/img/header/profile.svg" alt="Shippn Icon profile" loading="lazy">
          <span class="mobile-notification-center-messages-count" *ngIf="loggedUser && notifyMessagesCount > 0"
            [ngClass]="notifyMessagesCount > 0 ? 'shippn-orange' : 'shippn-black'">
            {{notifyMessagesCount ? notifyMessagesCount: 0}}
          </span>
        </button>
        <button type="button" class="shippn-mobile-menu-list-btn"
          (click)="closeMobileMenu();openNotificationcenterArea()" data-toggle="modal" data-target="#notificationModal"
          *ngIf="(loggedUser && notifyMessagesCount > 0) && !hello10Variant">
          <figure class="shippn-user-messages-figure">
            <img src="assets/img/header/message.svg" alt="Shippn message icon" loading="lazy">
            <span class="notification-center-messages-count" translate="no"
              [ngClass]="notifyMessagesCount > 0 ? 'show' : 'hide'" *ngIf="notifyMessagesCount > 0">
              {{notifyMessagesCount ? notifyMessagesCount: 0}}
            </span>
          </figure>
        </button>
        <button class="btn btn-shippn-header-shopping-cart" [routerLink]="['/',lang,'bfm']"
          [queryParams]="{anonymous_id: bfmUrlAnonymousId, cart_id: bfmUrlCartId }" (click)="closeMobileMenu();"
          *ngIf="basketVisible && !hello10Variant">
          <img src="assets/img/header/basket.svg" alt="Shippn Icon basket" loading="lazy">
          <span class="basket-product-count" *ngIf="shoppingCartItems.length > 0" translate="no">
            {{shoppingCartItems.length}}
          </span>
        </button>
        <button class="navbar-toggler" type="button" style="border:0; position: relative;" (click)="toggleMobileMenu()"
          [ngClass]="isMenuOpen ?  'opened': 'closed'" aria-label="Menu Controller">
          <svg width="40" height="40" viewBox="0 0 100 100">
            <path class="line line1"
              d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
            <path class="line line2" d="M 20,50 H 80" />
            <path class="line line3"
              d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
          </svg>

        </button>
      </div>
      <nav class="shippn-main-nav-mobile-menu" id="shippnMobileMenu" [ngClass]="isMenuOpen ?  'opened': 'closed'">


        <ul class="shippn-mobile-menu-list" *ngIf="!hello10Variant">

          <li class="shippn-mobile-menu-list-auth-buttons" *ngIf="!loggedUser">
            <button type="button" class="shippn-btn-orange" [routerLink]="['/',lang,'signup']" routerLinkActive="active"
              (click)="closeMobileMenu()">
              <img src="assets/img/header/profile_white.svg" alt="shippn profile white icon" width="24px" loading="lazy">
              Sign Up
            </button>
            <button type="button" class="shippn-btn-orange" [routerLink]="['/',lang,'signin']" routerLinkActive="active"
              (click)="closeMobileMenu()">
              <img src="assets/img/header/profile_white.svg" alt="shippn profile white icon" width="24px" loading="lazy">
              Sign In
            </button>

          </li>
          <li *ngIf="loggedUser">
            <button type="button" class="shippn-mobile-menu-list-btn" (click)="goDashboard()">
              <img src="assets/img/header/orders.svg" alt="Shippn orders icon" loading="lazy">
              <span *ngIf="loggedUser?.type === 1">Orders</span>
              <span *ngIf="loggedUser?.type === 2">Dashboard</span>
              <span *ngIf="loggedUser?.type === 4">My Store</span>
              <span *ngIf="loggedUser?.type === 5">B2B Dashboard</span>
            </button>
          </li>
          <li *ngIf="loggedUser">
            <button type="button" class="shippn-mobile-menu-list-btn"
              (click)="closeMobileMenu();openNotificationcenterArea()" data-toggle="modal"
              data-target="#notificationModal">
              <figure class="shippn-user-messages-figure">
                <img src="assets/img/header/message.svg" alt="Shippn message icon" loading="lazy">
                <span class="notification-center-messages-count" translate="no"
                  [ngClass]="notifyMessagesCount > 0 ? 'show' : 'hide'">
                  {{notifyMessagesCount ? notifyMessagesCount: 0}}
                </span>
              </figure>
              Messages
            </button>
          </li>
          <li *ngIf="loggedUser">
            <button type="button" class="shippn-mobile-menu-list-btn" routerLinkActive="active"
              (click)="toggleMobileDropDown('profileDropdown')">
              <img src="assets/img/header/profile.svg" alt="Shippn message icon" loading="lazy">
              My Profile
              <img src="assets/img/header/droprown_arrow_down.svg" alt="Shippn Icon dropdown arrow" loading="lazy">
            </button>

            <ul class="shippn-mobile-menu-list-dropdown" id="profileDropdown" (click)="closeMobileMenu();">
              <li>
                <button type="button" class="shippn-mobile-menu-list-btn"
                  [routerLink]="['/',lang,'settings', 'profile']" routerLinkActive="active">
                  Basic Info
                </button>
              </li>
              <li *ngIf="!loggedUser?.is_email_verified || !loggedUser?.is_phone_verified">
                <button type="button" class="shippn-mobile-menu-list-btn" [routerLink]="['/',lang,'verification']"
                  routerLinkActive="active">
                  Verification
                </button>
              </li>
              <li *ngIf="loggedUser?.type !== 4">
                <button type="button" class="shippn-mobile-menu-list-btn"
                  [routerLink]="['/',lang,'settings','addresses']" routerLinkActive="active">
                  Addresses
                </button>
              </li>
              <li *ngIf="loggedUser?.type === 2">
                <button type="button" class="shippn-mobile-menu-list-btn"
                  [routerLink]="['/',lang,'settings','availability']" routerLinkActive="active">
                  Host availability
                </button>
              </li>
              <li *ngIf="loggedUser?.type === 2">
                <button type="button" class="shippn-mobile-menu-list-btn"
                  [routerLink]="['/',lang,'settings','payment-info']" routerLinkActive="active">
                  Payment Info
                </button>
              </li>
              <li *ngIf="loggedUser?.type === 2">
                <button type="button" class="shippn-mobile-menu-list-btn"
                  [routerLink]="['/',lang,'settings','documents']" routerLinkActive="active">
                  Documents
                </button>
              </li>
              <li *ngIf="loggedUser?.type === 1">
                <button type="button" class="shippn-mobile-menu-list-btn"
                  [routerLink]="['/',lang,'settings','credit-card']" routerLinkActive="active">
                  Credit Card
                </button>
              </li>
              <li *ngIf="loggedUser?.type === 1">
                <button type="button" class="shippn-mobile-menu-list-btn"
                  [routerLink]="['/',lang,'settings','paypal-account']" routerLinkActive="active">
                  Paypal Account
                </button>
              </li>
              <li *ngIf="loggedUser?.type === 1">
                <button type="button" class="shippn-mobile-menu-list-btn" [routerLink]="['/',lang,'settings','refer']"
                  routerLinkActive="active">
                  Refer a Friend
                </button>
              </li>
              <li *ngIf="loggedUser?.type === 1">
                <button type="button" class="shippn-mobile-menu-list-btn"
                  [routerLink]="['/',lang,'settings','shippn-points']" routerLinkActive="active">
                  Shippn Points
                </button>
              </li>
              <li *ngIf="loggedUser?.type === 1">
                <button type="button" class="shippn-mobile-menu-list-btn" [routerLink]="['/',lang,'settings','coupon']"
                  routerLinkActive="active">
                  Coupon
                </button>
              </li>
              <li *ngIf="loggedUser?.type === 4">
                <button type="button" class="shippn-mobile-menu-list-btn"
                  [routerLink]="['/',lang,'settings','store-settings']" routerLinkActive="active">
                  Store Settings
                </button>
              </li>
              <li>
                <button type="button" class="shippn-mobile-menu-list-btn"
                  [routerLink]="['/',lang,'settings','change-password']" routerLinkActive="active">
                  Change Password
                </button>
              </li>
              <li>
                <button type="button" class="shippn-mobile-menu-list-btn"
                  [routerLink]="['/',lang,'settings','delete-account']" routerLinkActive="active">
                  Delete Account
                </button>
              </li>
            </ul>
          </li>
          <li>
            <button type="button" class="shippn-mobile-menu-list-btn" [routerLink]="['/',lang,'stores']"
              routerLinkActive="active" (click)="closeMobileMenu()">
              <img src="assets/img/header/top_stores.png" alt="Shippn Top Global Stores" loading="lazy" width="26px" height="26px">
              Top Global Stores
            </button>
          </li>
          <li>
            <button type="button" class="shippn-mobile-menu-list-btn"
              (click)="toggleMobileDropDown('locationsDropdown')">
              <img src="assets/img/header/locations.svg" alt="Shippn Icon locations" loading="lazy">
              Locations
              <img src="assets/img/header/droprown_arrow_down.svg" alt="Shippn Icon dropdown arrow" loading="lazy">
            </button>
            <ul class="shippn-mobile-menu-list-dropdown" id="locationsDropdown" (click)="closeMobileMenu();">
              <li *ngFor="let locationCountry of hostCountries; let i=index;">
                <button type="button" class="shippn-mobile-menu-list-btn"
                  [routerLink]="['/', lang,'shop-from', locationCountry?.code]" routerLinkActive="active">
                  <span
                    *ngIf="locationCountry?.code != 'TR' && locationCountry?.name != 'Turkey'">{{locationCountry?.name}}</span>
                  <span *ngIf="locationCountry?.code == 'TR' || locationCountry?.name == 'Turkey'"
                    translate="no">Türkiye</span>
                </button>
              </li>
              <li>
                <button type="button" class="shippn-mobile-menu-list-btn" [routerLink]="['/', lang,'shop-from']">
                  Show All
                </button>
              </li>
            </ul>
          </li>
          <li>
            <button type="button" class="shippn-mobile-menu-list-btn" (click)="toggleMobileDropDown('helpDropdown')">
              <img src="assets/img/header/help.svg" alt="Shippn Icon help" loading="lazy">
              Help
              <img src="assets/img/header/droprown_arrow_down.svg" alt="Shippn Icon dropdown arrow" loading="lazy">
            </button>

            <ul class="shippn-mobile-menu-list-dropdown" id="helpDropdown" (click)="closeMobileMenu();">
              <li>
                <button type="button" class="shippn-mobile-menu-list-btn" [routerLink]="['/',lang,'about']"
                  routerLinkActive="active">
                  About Us
                </button>
              </li>
              <li>
                <button type="button" class="shippn-mobile-menu-list-btn"
                  [routerLink]="['/',lang,'howitworks-for-shoppers']" routerLinkActive="active">
                  For Shoppers
                </button>
              </li>
              <li>
                <button type="button" class="shippn-mobile-menu-list-btn"
                  [routerLink]="['/',lang,'howitworks-for-hosts']" routerLinkActive="active">
                  For Hosts
                </button>
              </li>
              <li>
                <button type="button" class="shippn-mobile-menu-list-btn" [routerLink]="['/',lang,'faq']"
                  routerLinkActive="active">
                  FAQs
                </button>
              </li>
              <li>
                <button type="button" class="shippn-mobile-menu-list-btn" [routerLink]="['/',lang, 'trust-safety']"
                  routerLinkActive="active">
                  Trust & Safety
                </button>
              </li>
              <li>
                <button type="button" class="shippn-mobile-menu-list-btn" [routerLink]="['/',lang, 'prohibited-items']"
                  routerLinkActive="active">
                  Prohibited Items
                </button>
              </li>
              <li>
                <button type="button" class="shippn-mobile-menu-list-btn"
                  [routerLink]="['/', lang, 'shipping-standards']" routerLinkActive="active">
                  Shipping Standards
                </button>
              </li>
              <li>
                <button type="button" class="shippn-mobile-menu-list-btn"
                  [routerLink]="['/', lang, 'responsible-shipping']" routerLinkActive="active">
                  Responsible Shipping
                </button>
              </li>
              <li>
                <button type="button" class="shippn-mobile-menu-list-btn" [routerLink]="['/', lang, 'contact-us']"
                  routerLinkActive="active">
                  Contact Us
                </button>
              </li>
            </ul>
          </li>
          <li>
            <button type="button" class="shippn-mobile-menu-list-btn" [routerLink]="['/',lang,'bfm','explanation']"
              routerLinkActive="active" (click)="closeMobileMenu()">
              <img src="assets/img/header/BFM.svg" alt="Shippn Icon BFM " loading="lazy">
              Buy For Me Service
            </button>
          </li>
          <li *ngIf="!loggedUser || loggedUser?.type === 1">
            <button type="button" class="shippn-mobile-menu-list-btn"
              [routerLink]="['/',lang, 'shipping-cost-calculator']" routerLinkActive="active"
              (click)="closeMobileMenu();">
              <img src="assets/img/header/calculator.svg" alt="Shippn Icon calculator" loading="lazy">
              Shipping Cost Calculator
            </button>
          </li>
          <li *ngIf="!loggedUser">
            <button type="button" class="shippn-mobile-menu-list-btn"
              (click)="toggleMobileDropDown('becomeAPartnerDropdown')">
              <img src="assets/img/header/partner.svg" alt="Shippn Icon partner" loading="lazy">
              Become a Partner
              <img src="assets/img/header/droprown_arrow_down.svg" alt="Shippn Icon dropdown arrow" loading="lazy">
            </button>



            <ul class="shippn-mobile-menu-list-dropdown" id="becomeAPartnerDropdown" (click)="closeMobileMenu();">
              <li>
                <button type="button" class="shippn-mobile-menu-list-btn" [routerLink]="['/',lang,'b2b']"
                  routerLinkActive="active">
                  Create B2B Account
                </button>
              </li>
              <li>
                <button type="button" class="shippn-mobile-menu-list-btn" [routerLink]="['/',lang,'become-a-host']"
                  routerLinkActive="active">
                  Become a Host
                </button>
              </li>
              <li>
                <button type="button" class="shippn-mobile-menu-list-btn"
                  [routerLink]="['/',lang,'become-an-affiliate']" routerLinkActive="active">
                  Become An Affiliate
                </button>
              </li>
              <li>
                <button type="button" class="shippn-mobile-menu-list-btn" [routerLink]="['/',lang,'shipper-network']"
                  routerLinkActive="active">
                  Shipping Companies
                </button>
              </li>

            </ul>




          </li>
          <li>
            <button type="button" class="shippn-mobile-menu-list-btn" (click)="closeMobileMenu();openBlog()">
              <img src="assets/img/header/blog.svg" alt="Shippn Icon blog" loading="lazy">Blog
            </button>
          </li>
          <li *ngIf="loggedUser">
            <button type="button" class="shippn-mobile-menu-list-btn" (click)="closeMobileMenu();logout();">
              <img src="assets/img/header/logout.svg" alt="Shippn message icon" loading="lazy">
              Log Out
            </button>
          </li>
        </ul>
        <button class="shippn-mobile-menu-language-btn" data-toggle="modal" data-target="#translateLanguage">
          <img src="assets/img/header/language.svg" alt="Shippn message icon" loading="lazy">
          Language
        </button>
      </nav>
    </nav>
  </section>
</header>
</div>
<!-- Modal -->
<div class="modal fade" id="translateLanguage" tabindex="-1" aria-labelledby="Choose Language Modal" aria-hidden="true"
  data-backdrop="false">
  <div style="max-width: 800px;" class="modal-dialog modal-dialog-centered">
    <div class="modal-content" style="border-radius: 10px; padding: 20px;">
      <div class="header">
        <button type="button" id="translateLanguageCloser" class="close pull-right" data-dismiss="modal"
          aria-label="Close" style="margin-right: 15px">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- 'ar,it,es,zh-CN,zh-TW,hi,en,bn,id,fr,ru,pt,tr' -->
        <h4 style="margin-bottom: 25px; color: #707070;">Choose a language</h4>
        <div class="translate-languages-wrapper">
          <button class="btn" (click)="translateTo('en')">English</button>
          <button class="btn" (click)="translateTo('zh-CN')">Chinese</button>
          <button class="btn" (click)="translateTo('iw')">Hebrew</button>
          <button class="btn" (click)="translateTo('es')">Spanish</button>
          <button class="btn" (click)="translateTo('fr')">French</button>
          <button class="btn" (click)="translateTo('ar')">Arabic</button>
          <button class="btn" (click)="translateTo('de')">German</button>
          <button class="btn" (click)="translateTo('ru')">Russian</button>
          <button class="btn" (click)="translateTo('pt')">Portuguese</button>
          <button class="btn" (click)="translateTo('id')">Indonesian</button>
          <button class="btn" (click)="translateTo('it')">Italian</button>
          <button class="btn" (click)="translateTo('ja')">Japanese</button>
          <button class="btn" (click)="translateTo('ms')">Malay</button>
          <button class="btn" (click)="translateTo('nl')">Dutch</button>
          <button class="btn" (click)="translateTo('sv')">Swedish</button>
          <button class="btn" (click)="translateTo('no')">Norwegian</button>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div> -->
    </div>
  </div>
</div>
<!-- Message Modal -->
<ng-container *ngIf="openNotificationCenter">
  <shippn-notifications [notificationMessages]="notificationMessages"></shippn-notifications>
</ng-container>