import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CountrySearchPipe } from "src/app/pipes/countrySearch.pipe";
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { BarcodeScannerComponent } from "src/app/shared/features/barcode-scanner/barcode-scanner.component";
import { TermsContent } from "src/app/shared/contents/terms-content/terms-content";
import { MessagesDirectiveComponent } from "src/app/directives/messages/messages-directive/messages-directive.component";
import { NgxDropzoneModule } from "ngx-dropzone";
import { TrimPipe } from "src/app/pipes/trim.pipe";
import { PrivacyContent } from "src/app/shared/contents/privacy-content/privacy-content";
import { CurrencySearchPipe } from "src/app/pipes/currencySearch.pipe copy";
import { PreventEscOnInputDirective } from "src/app/directives/escPrevent.directive";
import { ProductImageCropperDialog } from "src/app/shared/features/product-image-cropper/product-image-cropper.component";
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LySliderModule } from '@alyle/ui/slider';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LyDialogModule } from '@alyle/ui/dialog';
import { SortByPipe } from "src/app/pipes/sortBy.pipe";
import { B2BTermsContent } from "src/app/shared/contents/b2b-terms-content/b2b-terms-content";
import { HtsContentSearchPipe } from "src/app/pipes/htsContentSearch.pipe";
import { TrustpilotWidgetComponent } from "src/app/components/trustpilot-widget/trustpilot-widget.component";
import { YTPlayerDialog } from "src/app/shared/features/yt-player-dialog/yt-player-dialog";
import { MatDialogModule } from "@angular/material/dialog";
import { SafeUrlPipe } from "src/app/pipes/safeurl.pipe";
@NgModule({
    declarations: [
        CountrySearchPipe,
        CurrencySearchPipe,
        BarcodeScannerComponent,
        TermsContent,
        B2BTermsContent,
        MessagesDirectiveComponent,
        TrimPipe,
        PrivacyContent,
        PreventEscOnInputDirective,
        ProductImageCropperDialog,
        SortByPipe,
        HtsContentSearchPipe,
        TrustpilotWidgetComponent,
        YTPlayerDialog,
        SafeUrlPipe
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ZXingScannerModule,
        NgxDropzoneModule,
        LyImageCropperModule,
        LySliderModule,
        LyButtonModule,
        LyIconModule,
        LyDialogModule,
        MatDialogModule
    ],
    exports: [
        CountrySearchPipe,
        CurrencySearchPipe,
        BarcodeScannerComponent,
        TermsContent,
        B2BTermsContent,
        MessagesDirectiveComponent,
        TrimPipe,
        PrivacyContent,
        PreventEscOnInputDirective,
        SortByPipe, 
        HtsContentSearchPipe, 
        TrustpilotWidgetComponent,
        YTPlayerDialog,
        SafeUrlPipe
    ]
})

export class SharedModule { }